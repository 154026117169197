import type { UrlObject } from 'url';
import React, { createContext, FC, useContext } from 'react';
import { TestTaskType } from '@/controllers/graphql/generated';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export interface LinearFlowConfig {
  isAiAssistantEnabled: boolean;
  isTaskComplexityEnabled: boolean;
  backButtonUrl?: UrlObject | string;
  isLeftSidebarCollapsedInitial: boolean;
  shouldItemBeSelectedOnMobileInitially: boolean;
  testTaskType: TestTaskType;
}

const LinearFlowConfigContext = createContext<Required<LinearFlowConfig>>({
  isAiAssistantEnabled: true,
  isTaskComplexityEnabled: false,
  backButtonUrl: '',
  isLeftSidebarCollapsedInitial: false,
  shouldItemBeSelectedOnMobileInitially: false,
  testTaskType: TestTaskType.TopicTasks,
});

export const useLinearFlowConfigContext = () => useContext(
  LinearFlowConfigContext,
);

interface Props {
  config: LinearFlowConfig;
  children: React.ReactNode;
}

export const LinearFlowConfigContextProvider: FC<Props> = (props) => {
  const {
    config,
    children,
  } = props;
  const learningRoot = renderRoute(allRoutes.study.autoRedirect);

  const providerValue = config.backButtonUrl
    ? config as Required<LinearFlowConfig>
    : {
      ...config,
      backButtonUrl: learningRoot,
    };

  return (
    <LinearFlowConfigContext.Provider value={providerValue}>
      {children}
    </LinearFlowConfigContext.Provider>
  );
};
