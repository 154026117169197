import { Features } from '@/controllers/features/features.constants';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { getCourseTopicLinkParams } from '@/controllers/router/router.utils/learn';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { AnyObject } from '@/typedefs/common';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import { readLearnItemFilterParam } from '@/components/platform/Learn/hooks/useLearnQueryParams';
import { topicSectionByURL } from '@/components/platform/Learn/CourseTopic/URLByTopicSection';
import { practiceTabURLToPracticeTabs } from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/practiceTabURLToPracticeTabs';
import { LearnItemFilter } from '@/components/platform/Learn/LinearFlow/hooks/learnItemsFilter/useLearnItemsFilter';
import { PracticeTabs } from '@/components/platform/Learn/pages/CourseTopic/components/MobilePractice/MobilePracticeTabs/PracticeTabs.constants';

export enum StudyRouteParamsKeys {
  ProfessionSlug = 'professionSlug',
  ModuleSlug = 'moduleSlug',
  CourseTopicSlug = 'courseTopicSlug',
  TestTaskSlug = 'testTaskSlug',
  TheoryId = 'theoryId',
  VideoId = 'videoId',
  Section = 'section',
  TaskSection = 'taskSection',
  LearnItemsFilter = 'learnItemsFilter',
}

export type StudyRouteParams = {
  [StudyRouteParamsKeys.ProfessionSlug]: string;
  [StudyRouteParamsKeys.ModuleSlug]: string;
  [StudyRouteParamsKeys.CourseTopicSlug]: string;
  [StudyRouteParamsKeys.TestTaskSlug]: string;
  [StudyRouteParamsKeys.Section]: TopicSections;
  [StudyRouteParamsKeys.TaskSection]: PracticeTabs | null;
  [StudyRouteParamsKeys.VideoId]: number;
  [StudyRouteParamsKeys.TheoryId]: number | null;
  [StudyRouteParamsKeys.LearnItemsFilter]: LearnItemFilter;
};

const {
  ProfessionSlug,
  ModuleSlug,
  CourseTopicSlug,
  TestTaskSlug,
  TheoryId,
  VideoId,
  Section,
  TaskSection,
  LearnItemsFilter,
} = StudyRouteParamsKeys;

const queryMapper = (query: AnyObject): StudyRouteParams => ({
  [ProfessionSlug]: query[ProfessionSlug] || '',
  [ModuleSlug]: query[ModuleSlug] || '',
  [CourseTopicSlug]: query[CourseTopicSlug] || '',
  [TestTaskSlug]: query[TestTaskSlug] || '',
  [TheoryId]: query[TheoryId]
    ? Number(query.theoryId)
    : null,
  [VideoId]: query[VideoId]
    ? Number(query[VideoId])
    : NaN,
  [Section]: topicSectionByURL[query[Section]] ?? TopicSections.Video,
  [TaskSection]: query[TaskSection]
    ? practiceTabURLToPracticeTabs(query[TaskSection])
    : null,
  [LearnItemsFilter]: readLearnItemFilterParam(
    query[LearnItemsFilter],
  ),
});

export const useStudyRouteParams = () => useRouterQuery<StudyRouteParams>(
  queryMapper,
);

export const isValidProfessionSlug = (
  availableProfessionSlugs: string[],
  professionSlug: string,
) => availableProfessionSlugs.includes(professionSlug);

interface EnsureCorrectProfessionSlugInLSParams {
  availableProfessionSlugs: string[];
  professionSlugFromQuery: string;
  courseSlugFromLS: string;
  setCourseSlugInLS: (value: string) => void;
}

export const ensureCorrectProfessionSlugInLS = (
  params: EnsureCorrectProfessionSlugInLSParams,
) => {
  const {
    availableProfessionSlugs,
    professionSlugFromQuery,
    courseSlugFromLS,
    setCourseSlugInLS,
  } = params;

  try {
    const isProfessionFromURLValid = isValidProfessionSlug(
      availableProfessionSlugs,
      professionSlugFromQuery,
    );

    if (
      professionSlugFromQuery
      && courseSlugFromLS !== professionSlugFromQuery
      && isProfessionFromURLValid
    ) {
      setCourseSlugInLS(professionSlugFromQuery);

      return professionSlugFromQuery;
    }

    const isCourseFromLSValid = isValidProfessionSlug(
      availableProfessionSlugs,
      courseSlugFromLS,
    );

    if (isCourseFromLSValid) {
      return courseSlugFromLS;
    }

    return isProfessionFromURLValid
      ? professionSlugFromQuery
      : '';
  } catch (error) {
    return '';
  }
};

interface GetProfessionRouteForRedirectParams {
  professionSlugFromURL: string;
  courseSlugFromLS: string;
  availableProfessionSlugs: string[];
}

export const resolveMostRelevantProfessionRouteForRedirect = (
  params: GetProfessionRouteForRedirectParams,
): string | null => {
  const {
    professionSlugFromURL,
    courseSlugFromLS,
    availableProfessionSlugs,
  } = params;

  const isValidProfessionFromURL = isValidProfessionSlug(
    availableProfessionSlugs,
    professionSlugFromURL,
  );

  if (isValidProfessionFromURL) {
    return null;
  }

  const isValidProfessionFromLS = isValidProfessionSlug(
    availableProfessionSlugs,
    courseSlugFromLS,
  );

  if (isValidProfessionFromLS) {
    return renderRoute(allRoutes.study.profession, {
      professionSlug: courseSlugFromLS,
    });
  }

  return renderRoute(allRoutes.study);
};

interface GetCourseTopicRouteForRedirectParams {
  professionSlug: string;
  courseTopicSlug: string;
  moduleSlug: string;
  baseRoute: string;
  section: string;
}

export const getCourseTopicRouteForRedirect = (
  params: GetCourseTopicRouteForRedirectParams,
) => {
  const {
    professionSlug,
    courseTopicSlug,
    moduleSlug,
    baseRoute,
    section,
  } = params;

  if (professionSlug) {
    return renderRoute(allRoutes.study.profession.courseTopic, {
      professionSlug,
      courseTopicSlug,
      moduleSlug,
    });
  }

  const topicLink = getCourseTopicLinkParams(
    moduleSlug,
    courseTopicSlug,
    baseRoute,
    section,
  );

  return topicLink.href;
};

export const getLearningRootForRedirect = (
  isHomePageEnabled: boolean,
) => {
  if (isHomePageEnabled) {
    return renderRoute(allRoutes.home);
  }

  return renderRoute(allRoutes.study.autoRedirect);
};

export const useLearningRootForRedirect = () => {
  const features = useFeatures();
  const isHomePageEnabled = features.isEnabled(Features.HomePage);

  return getLearningRootForRedirect(isHomePageEnabled);
};

export const useProfessionUrlForRedirect = (professionSlugFromURL?: string) => {
  if (!professionSlugFromURL) {
    return renderRoute(allRoutes.study.autoRedirect);
  }

  return renderRoute(allRoutes.study.profession, {
    professionSlug: professionSlugFromURL,
  });
};

export type GetTopicUrlForRedirectParams = {
  professionSlug: string;
  moduleSlug: string;
  courseTopicSlug: string;
};

export const useTopicUrlForRedirect = (
  params: GetTopicUrlForRedirectParams,
) => {
  const {
    professionSlug,
    moduleSlug,
    courseTopicSlug,
  } = params;

  return renderRoute(allRoutes.study.profession.courseTopic, {
    // NOTE: That's just in case. I cannot be sure professionSlug 100% exists.
    // So we need to provide some default value to avoid errors.
    // Study page will find the relevant profession to the module and redirect to it.
    professionSlug: professionSlug || 'unknown',
    moduleSlug,
    courseTopicSlug,
  });
};

export type GetTopicUrlForUnsureRedirectParams = {
  moduleSlug?: string;
  courseTopicSlug?: string;
  section?: TopicSections;
  testTaskSlug?: string;
  videoId?: number;
};

export const useTopicUrlForUnsureRedirect = (
  params: GetTopicUrlForUnsureRedirectParams,
): string | null => {
  const {
    moduleSlug,
    courseTopicSlug,
    testTaskSlug,
    section,
    videoId,
  } = params;

  const [
    professionSlugFromLS,
  ] = useLocalStorage(LOCAL_STORAGE_KEYS.selectedLearnCourseSlug, '');

  const {
    professionSlug: professionSlugFromURL,
  } = useStudyRouteParams();

  if (!moduleSlug || !courseTopicSlug) {
    return null;
  }

  const professionSlug = professionSlugFromURL || professionSlugFromLS;

  if (!professionSlug) {
    /**
     * NOTE:
     * This is a quite impossible case. Because if we on the /learn
     * we usually have a profession slug in the localStorage.
     * If we on /study - we always have a profession slug in the URL.
     * But if we on the learn and have no profession slug in the localStorage
     * we redirect to all courses page. User will choose the course and problem will never happen again.
     */
    return renderRoute(allRoutes.study.autoRedirect);
  }

  return renderRoute(allRoutes.study.profession.courseTopic, {
    professionSlug,
    moduleSlug,
    courseTopicSlug,
    section,
    testTaskSlug,
    videoId,
  });
};

export const getTopicUrlForRedirect = async (
  moduleSlug: string,
  courseTopicSlug: string,
  resolveParentCourse: () => Promise<{ id: number; slug: string } | null>,
): Promise<string> => {
  const parentCourse = await resolveParentCourse();

  if (!parentCourse) {
    return renderRoute(allRoutes.home);
  }

  return renderRoute(allRoutes.study.profession.courseTopic, {
    professionSlug: parentCourse.slug,
    moduleSlug,
    courseTopicSlug,
  });
};
